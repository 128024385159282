<template>
  <div>
    <vue-bottom-sheet-vue2
      custom-class="payment-method-bottom-sheet"
      ref="paymentMethodBottomSheet"
      :z-index="1000"
    >
      <template #header>
        <div class="header">{{ $t("payermax.choose_payment_method") }}</div>
      </template>
      <template #default>
        <div class="main-container" id="main-container">
          <div
            v-for="(paymentMethod, index) in paymentMethods"
            :key="index"
            :class="[
              `product-container`,
              {
                'product-container-selected':
                  selectedPaymentMethod !== null &&
                  paymentMethod.name === selectedPaymentMethod.name,
              },
            ]"
            @click="onPaymentMethodSelect(paymentMethod)"
          >
            <div class="product-inner-container">
              <div class="payment-type">
                <div class="payment-type-icon">
                  <img :src="paymentMethod.icon_url" alt="payment-type-icon" />
                </div>
                <div class="name-container">
                  <div
                    v-if="
                      paymentMethod.payment_price !== null &&
                      (paymentMethod.payment_price.discount ||
                        paymentMethod.payment_price.coin_increase_percentage)
                    "
                    class="discount-badge"
                  >
                    <div
                      v-if="
                        paymentMethod.payment_price.coin_increase_percentage
                      "
                      class="discount-text"
                    >
                      +%
                      {{ paymentMethod.payment_price.coin_increase_percentage }}
                    </div>
                    <div v-else class="discount-text">
                      % {{ paymentMethod.payment_price.discount }}
                      {{ $t("labels.discount") }}
                    </div>
                  </div>
                  <div class="name">{{ paymentMethod.name }}</div>
                </div>
              </div>
              <div
                v-if="!isDealerPaymentMethod(paymentMethod)"
                class="coin-info"
              >
                <div class="coin-icon">
                  <img :src="require(`@/assets/img/coin.svg`)" alt="coin" />
                </div>
                <div class="coin-amount">
                  <div
                    v-if="
                      paymentMethod.payment_price !== null &&
                      paymentMethod.payment_price.overriden_coin_amount
                    "
                  >
                    <div v-if="isCoinIncreaseShown" class="initial-coin-amount">
                      {{
                        calculateInitialCoinAmount(
                          paymentMethod.payment_price.overriden_coin_amount,
                          paymentMethod.payment_price.coin_increase_percentage
                        )
                      }}
                    </div>
                    <div class="amount">
                      {{ paymentMethod.payment_price.overriden_coin_amount }}
                    </div>
                  </div>
                  <div v-else class="amount">
                    {{ product.amount }}
                  </div>
                </div>
              </div>
              <div v-else class="dealer-button">
                <img :src="require('@/assets/img/greater-icon.svg')" />
              </div>
            </div>
          </div>
        </div>
        <div class="pay-button-container">
          <div
            class="pay-button"
            @click="onProceedPayment(product, selectedPaymentMethod)"
          >
            <div v-if="!isDealerPaymentMethod(selectedPaymentMethod)">
              {{ currency }}
              {{
                selectedPaymentMethod != null
                  ? selectedPaymentMethod.payment_price.price
                  : 0
              }}
              {{ $t("labels.do_payment") }}
            </div>
          </div>
        </div>
        <div class="payment-method-footer">
          <button class="customer-service" @click="onCustomerServiceOpen">
            <img
              src="@/assets/img/headphone-icon-new.svg"
              alt="Customer Service"
            />
            <span>{{ $t("payermax.customer_service") }}</span>
          </button>
          <div class="privacy-link">
            <span class="link" @click="onLinkOpenInDevice(privacyPolicyLink)">{{
              $t("menu.terms_of_service")
            }}</span>
            <span class="link-separator">&</span>
            <span
              class="link"
              @click="onLinkOpenInDevice(termsOfServiceLink)"
              >{{ $t("menu.privacy_policy") }}</span
            >
          </div>
        </div>
        <v-overlay color="#fff" :value="isFullLoading">
          <v-progress-circular color="#333" :size="50" indeterminate />
        </v-overlay>
      </template>
    </vue-bottom-sheet-vue2>
    <SavedCardsBottomSheet
      ref="savedCardsBottomSheet"
      :data="savedPaymentTokens"
      :currency="currency"
      :coin-amount="coinAmount"
      :price="
        selectedPaymentMethod != null
          ? selectedPaymentMethod.payment_price.price
          : 0
      "
      :show-full-page-loading="isSavedPaymentTokenLoading"
      :show-save-card-permission="
        isSaveCardPermissionAvailable(selectedPaymentMethod)
      "
      :provider="
        selectedPaymentMethod != null
          ? selectedPaymentMethod.payment_provider
          : null
      "
      :show-add-card="isAddCardOptionAvailable(selectedPaymentMethod)"
      @delete-card="onDeleteCard"
      @add-card="onAddCard"
      @pay="onSavedCardPay"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import VueBottomSheetVue2 from "@webzlodimir/vue-bottom-sheet-vue2";
import { PaymentProvider } from "@/helper/enums";
import { generatePaymentUUID } from "@/helper";
import PaymentMethodProceedMixin from "./PaymentMethodProceedMixin";
import PaymentTokenOperationMixin from "./PaymentTokenOperationMixin";
import SavedCardsBottomSheet from "@/components/Payment/SavedCard/SavedCardsBottomSheet.vue";

/** @import  {Payment} from '@/components/Payment/types/payment' */
/** @import  {Product} from '@/components/Payment/types/product' */

/**
 * @typedef ReactiveData
 * @property {Payment.PaymentMethod|null} selectedPaymentMethod
 * @property {Array<Payment.PaymentMethod>} paymentMethods
 * @property {boolean} isFullLoading
 */

/**
 * @typedef Props
 * @property {number} selectedOfferIndex
 * @property {string} privacyPolicyLink
 * @property {string} termsOfServiceLink
 * @property {string} supportUserId
 * @property {Product.CoinProduct} product
 */

/** @typedef {import('vue') & ReactiveData & Props} Component */

/**
 * @mixes PaymentMethodProceedMixin
 * @mixes PaymentTokenOperationMixin
 */
export default {
  name: "PaymentMethodBottomSheet",
  /**
   * @returns {ReactiveData}
   */
  data() {
    return {
      selectedPaymentMethod: null,
      paymentMethods: [],
      isFullLoading: false,
    };
  },
  components: { SavedCardsBottomSheet, VueBottomSheetVue2 },
  mixins: [PaymentMethodProceedMixin, PaymentTokenOperationMixin],
  props: [
    "selectedOfferIndex",
    "privacyPolicyLink",
    "termsOfServiceLink",
    "supportUserId",
    "product",
  ],
  computed: {
    ...mapState({
      currencies: (state) => state.client.currencies,
    }),
    isCoinIncreaseShown() {
      let isInAppPurchaseExists = false;
      let isPriceDiscountModeEnabled = false;

      for (const paymentMethod of this.paymentMethods) {
        if (
          [PaymentProvider.APPLE, PaymentProvider.GOOGLE].includes(
            paymentMethod.payment_provider
          )
        ) {
          isInAppPurchaseExists = true;
        } else if (
          [
            PaymentProvider.PAYERMAX,
            PaymentProvider.RAPYD,
            PaymentProvider.TRUSTPAY,
            PaymentProvider.PAYPAL,
          ].includes(paymentMethod.payment_provider)
        ) {
          isPriceDiscountModeEnabled =
            paymentMethod.payment_price.overriden_coin_amount === undefined;
        }
      }

      return !isInAppPurchaseExists && !isPriceDiscountModeEnabled;
    },
    /**
     * @this Component
     * @returns {number}
     */
    coinAmount() {
      if (this.selectedPaymentMethod != null) {
        return (
          this.selectedPaymentMethod.payment_price.overriden_coin_amount ??
          this.product.amount
        );
      }

      return 0;
    },
    /** @returns {string|null} */
    currency() {
      if (
        this.selectedPaymentMethod != null &&
        this.selectedPaymentMethod.payment_price != null
      ) {
        const currency =
          this.selectedPaymentMethod.payment_price.currency.toUpperCase();
        const symbol = this.currencies[currency];

        return symbol ?? currency;
      }

      return null;
    },
    /** @returns {number|null} */
    price() {
      return this.selectedPaymentMethod != null &&
        this.selectedPaymentMethod.payment_price != null
        ? this.selectedPaymentMethod.payment_price.price
        : null;
    },
  },
  methods: {
    /**
     * @param {Payment.PaymentMethod|null} paymentMethod
     * @returns {boolean}
     */
    isDealerPaymentMethod(paymentMethod) {
      return paymentMethod?.payment_provider === PaymentProvider.DEALER;
    },
    onCustomerServiceOpen() {
      this.$hybridapi("openNativeConversation", this.supportUserId);
    },
    /**
     * @param {string} link
     */
    onLinkOpenInDevice(link) {
      this.$hybridapi("openLinkInBrowser", link);
    },
    /**
     * @param {Payment.PaymentMethod} paymentMethod
     */
    async onRetrieveSavedPaymentToken(paymentMethod) {
      this.clearSavedPaymentTokens();
      if (paymentMethod.payable_with_saved_card) {
        await this.getSavedPaymentTokens(paymentMethod.payment_provider);
      }
    },
    /**
     * @this Component
     * @param {Payment.PaymentMethod} paymentMethod
     */
    async onPaymentMethodSelect(paymentMethod) {
      if (paymentMethod.payment_provider === PaymentProvider.DEALER) {
        this.$store.dispatch("payment/setSelectedProduct", this.product);
        this.$hybridapi("showLoading");
        this.$refs.paymentMethodBottomSheet.close();
        this.$router.push("/payermax/coin-reseller");
      } else {
        this.selectedPaymentMethod = paymentMethod;
        await this.onRetrieveSavedPaymentToken(paymentMethod);
      }
    },
    /**
     * @param {number} overriddenCoinAmount
     * @param {number} coinIncreasePercentage
     */
    calculateInitialCoinAmount(overriddenCoinAmount, coinIncreasePercentage) {
      return Math.ceil(
        overriddenCoinAmount * (100 / (coinIncreasePercentage + 100))
      );
    },
    /**
     * @this Component
     * @param {Payment.SavedPaymentToken | Payment.OlderSavedPaymentToken} savedPaymentToken
     */
    async onDeleteCard(savedPaymentToken) {
      await this.proceedDeleteCard(
        savedPaymentToken,
        this.selectedPaymentMethod.payment_provider
      );
    },
    /**
     * @this Component
     * @param {boolean} isSaveCard
     */
    async onAddCard(isSaveCard) {
      await this.proceedAddCard(
        this.product.sku,
        this.selectedPaymentMethod,
        isSaveCard
      );
    },
    /**
     * @this Component
     * @param {Payment.SavedPaymentToken | Payment.OlderSavedPaymentToken | null} savedPaymentToken
     */
    async onSavedCardPay(savedPaymentToken) {
      await this.proceedSavedCardPay(
        this.product.sku,
        savedPaymentToken,
        this.selectedPaymentMethod
      );
    },
    /**
     * @this Component
     */
    open() {
      this.paymentMethods = /** @type {Payment.PaymentMethod[]} */ (
        this.product.payment_types.filter((it) => it.is_available)
      );

      if (this.paymentMethods.length > 1) {
        if (
          this.paymentMethods[0].payment_provider == PaymentProvider.PAYERMAX ||
          this.paymentMethods[0].payment_provider == PaymentProvider.RAPYD ||
          this.paymentMethods[0].payment_provider == PaymentProvider.STRIPE ||
          this.paymentMethods[0].payment_provider == PaymentProvider.TRUSTPAY ||
          this.paymentMethods[0].payment_provider == PaymentProvider.RAZER ||
          this.paymentMethods[0].payment_provider == PaymentProvider.PAYPAL
        ) {
          this.selectedPaymentMethod = this.paymentMethods[0];
        } else if (
          this.selectedOfferIndex < this.paymentMethods.length &&
          this.paymentMethods[this.selectedOfferIndex].payment_provider ==
            PaymentProvider.DEALER
        ) {
          this.selectedPaymentMethod = this.paymentMethods[0];
        } else if (this.selectedOfferIndex < this.paymentMethods.length) {
          this.selectedPaymentMethod =
            this.paymentMethods[this.selectedOfferIndex];
        } else {
          this.selectedPaymentMethod = this.paymentMethods[0];
        }
      } else {
        this.selectedPaymentMethod = this.paymentMethods[0];
      }

      generatePaymentUUID();
      this.onRetrieveSavedPaymentToken(this.selectedPaymentMethod);
      this.$refs.paymentMethodBottomSheet.open();
    },
    handleSwipe(e, element) {
      if (element.scrollTop != 0) {
        e.stopPropagation();
      }
    },
  },
  mounted() {
    const scrollableContainer = document.getElementById("main-container");
    scrollableContainer.addEventListener("touchend", (e) => {
      this.handleSwipe(e, scrollableContainer);
    });
    scrollableContainer.addEventListener("touchmove", (e) => {
      this.handleSwipe(e, scrollableContainer);
    });
  },
};
</script>

<style scoped lang="scss">
@import "./PaymentMethodBottomSheet.scss";
</style>
